// String manipulation pipe

import { rxGroupsLength } from "./utils.js";

export const Xs = xs => s => pipe(s, ...xs);
export const X  = (...xs) => s => pipe(s, ...xs);

export const pipe = (s, ...xs) => {
  const state = typeof s === "object" ? s : Object(s);
  for (const x of xs) {
    const typ = typeof (x ?? undefined);
    if (typ === "undefined") continue;
    s = typ === "function" ? x(s, state) : x;
  }
  return s;
};

export const _key = key => x => x[key]; // use property
export const _method = key => (...xs) => x => x[key](...xs); // call methods
export const _method0 = key => x => x[key](); // call methods, no args
export const _do = f => (s, ...xs) => (f(s, ...xs), s); // call function for side effect
export const _trim = _method0("trim"), _replace = _method("replace");
export const _trimLines = s => s.replace(/^([ \t]*\r?\n)+/, "").trimEnd();

// Repeat a function until no more changes
export const _repeat = f => s => {
  while (true) {
    const s1 = f(s);
    if (s1 === s) return s;
    s = s1;
  }
};

// Balanced delimiter utility

export const replaceOpenClose = ([rxOpen, rxClose, isMatch], replacer) => {
  const rx = RegExp(`(?:${rxOpen.source}(?<__OPEN__>))|(?:${rxClose.source})`,
                    [...new Set("g" + rxOpen.flags + rxClose.flags)].join(""));
  // This would works when RegExp Modifiers are a thing
  // const rx = RegExp(
  //   `(?${rxOpen.flags.replace(/[^ims]+/g, "")}:${rxOpen.source}(?<__OPEN__>))`
  //   + `|(?${rxClose.flags.replace(/[^ims]+/g, "")}:${rxClose.source})`,
  //   [...new Set(("g" + rxOpen.flags + rxClose.flags).replace(/[ims]+/g, ""))]
  //     .join(""));
  const openGroups = rxGroupsLength(rxOpen);
  // A match has 1 + openGroups + 1 + closeGroups groups: first 1 is \0, second
  // is the (?<__o__>) which is used as a marker to know if its an opening.
  // Could use () around the first, but this adds an implicit group, so without
  // it numeric backrefs in rxOpen work as expected. (To sanely backref in
  // rxClose, use named backrefs: \k<foo>.) The following rotates a closing
  // match result so that the groups are in order too.
  const rotateCloser = m => m.push(...m.splice(1, openGroups + 1));
  return s => {
    const delims = [...s.matchAll(rx)];
    // the stack is a list of: [text, opener, text, ..., opener, text]
    const stack = [s.substring(0, delims[0]?.index)];
    delims.forEach((d, i) => {
      const next = s.substring(
        d.index + d[0].length,
        i < delims.length-1 ? delims[i+1].index : undefined);
      if (d.groups.__OPEN__ === "") { // open
        stack.push(d, next);
      } else if (stack.length === 1) { // dangling close
        stack[0] += d[0] + next;
      } else { // closing a match
        rotateCloser(d);
        if (!isMatch || isMatch(stack.at(-2), d)) { // simple case
          const r = replacer(stack.pop(), stack.pop(), d); // text, open, close
          stack[stack.length-1] = stack.at(-1) + r + next;
        } else { // need to look for a match
          let i = stack.length - 4;
          while (i >= 0 && !isMatch(stack[i], d)) i -= 2;
          if (i < 0) {
            stack[stack.length-1] += d[0] + next; // no match, same as dangling
          } else { // found a match, the following are all used as strings
            const open = stack[i];
            const text = stack.slice(i+1).map(x =>
              typeof x === "string" ? x : x[0]).join("");
            stack.length = i; // throw out the stuff that was used
            const r = replacer(text, open, d);
            stack[stack.length-1] = stack.at(-1) + r + next;
          }
        }
      }
    });
    return stack.map(x => typeof x === "string" ? x : x[0]).join("");
  };
};
