import { win, doc, conf } from "./globals.js";

const chunks = {
  code_copy: () => {
    doc.head.append(
      mk("style",
         "  pre:has(> code) {",
         "    position: relative;",
         "    &:after {",
         "      content: \"📋\";",
         "      position: absolute; display: block; overflow: hidden; direction: rtl;",
         "      top: -0.24ex; right: calc(-1px - 1.5ex); width: 0;",
         "      padding: 0.25ex 0.5ex 0.25ex 1ex; font-size: 75%;",
         "      height: calc(100% - 0.5ex); max-height: 1.5em;",
         "      opacity: 0; border: #444 solid 2px; border-left: none;",
         "      background-color: #222; border-radius: 0 1ex 1ex 0;",
         "      transition: width 200ms, right 200ms, opacity 200ms;",
         "      transition-delay: 500ms;",
         "    }",
         "    &:hover:after {",
         "      right: calc(-1px - 1.5ex - 1em); width: 1em; opacity: 1;",
         "      transition-delay: 100ms;",
         "    }",
         "  }"));
    win.addEventListener("click", ({ target, offsetX }) => {
      if (target.nodeName !== "PRE") return;
      if (offsetX <= target.offsetWidth) return;
      navigator.clipboard.writeText(target.textContent);
      const style = target.style;
      Object.assign(style, { backgroundColor: "#88c8" });
      setTimeout(() =>
        Object.assign(style, { transition: "background-color 300ms", backgroundColor: "" }),
        500);
      setTimeout(() =>
        Object.assign(style, { transition: "" }),
        800);
    });
  },

  toc: () => {
    const smallMargin = 4, bigMargin = 10, handleWidth = 20;
    doc.head.append(
      mk("style",
         `#toc:before {`,
         `  content: "§"; display: block; position: absolute; left: 0; top: 0;`,
         `  width: ${handleWidth}px; text-align: center; line-height: 4ex;`,
         `  background-color: #66c8; height: var(--handle-height, 100%); }`,
         `#toc {`,
         `  position: fixed; z-index: 1; right: 0;`,
         `  overflow: auto; scrollbar-width: none; max-height: 50vh;`,
         `  padding: ${bigMargin}px ${smallMargin}px`
         +         ` ${bigMargin}px ${handleWidth + smallMargin}px;`,
         `  background-color: #8886; border-radius: ${smallMargin}px;`,
         `  white-space: nowrap; backdrop-filter: blur(5px);`,
         `  transform: translateX(calc(100% - 24px));`,
         `  transition: all 0.25s ease-in-out; transition-delay: 500ms;`,
         `  text-align: left;`,
         `  &:hover {`,
         `    transform: translateX(0); transition-delay: 100ms; }`,
         `  & a {`,
         `    cursor: pointer;`,
         `    &.H1 { font-size: 100%; font-weight: bold;   margin-left: 0ex; }`,
         `    &.H2 { font-size:  80%; font-weight: bold;   margin-left: 1ex; }`,
         `    &.H3 { font-size:  70%; font-weight: normal; margin-left: 2ex; }`,
         `    &.H4 { font-size:  60%; font-weight: normal; margin-left: 3ex; }`,
         `    &.H5 { font-size:  50%; font-weight: normal; margin-left: 4ex; }}`,
         `}`));
    win.addEventListener("md-rendered", () => {
      if (win.innerHeight >= doc.body.scrollHeight) return;
      const headers = doc.querySelectorAll("h1,h2");
      if (headers.length < 3) return;
      const toc = mk("div", { id: "toc" });
      for (const h of doc.querySelectorAll("h1,h2")) {
        const a = mk("a", { classList: h.tagName });
        a.innerHTML = h.innerHTML;
        const st = h.style;
        a.addEventListener("click", ()=> {
          st.scrollMarginTop = "1ex"; st.transition = "all 300ms";
          st.color = "black"; st.backgroundColor = "white";
          setTimeout(()=> {
            st.scrollMarginTop = ""; st.color = ""; st.backgroundColor = "";
            setTimeout(()=> st.transition = "", 500);
          }, 1000);
          h.scrollIntoView({ behavior: "smooth", block: "start" });
        });
        toc.append(a, mk("br"));
      }
      const w = doc.querySelector("#widgets");
      if (w) { w.append(toc); toc.style.top = w.offsetHeight + "px"; }
      else doc.querySelector(".md-rendered").prepend(toc);
      toc.style.setProperty("--handle-height",
                            `${toc.scrollHeight}px`);
    });
  },

  mermaid: async () => {
    const { default: mermaid } =
      await import("https://cdn.jsdelivr.net/npm/mermaid@10/dist/mermaid.esm.min.mjs");
    mermaid.initialize({
      startOnLoad: false,
      theme: "dark",
      securityLevel: "loose",
    });
    doc.head.append(
      mk("style", "pre:has(> svg) { margin-inline: auto; border-radius: 1ex; }"));
    doc.querySelectorAll("pre > code.language-mermaid").forEach(async (elt, i) => {
      try {
        const { svg, bindFunctions } = await mermaid.render("mermaid-"+i, elt.textContent);
        elt.outerHTML = svg; bindFunctions?.(elt);
      } catch (e) { console.error("Rendering error: "+e?.message || e); }
    });
  },

  // standard mathjax use
  mathjax: async () => {
    const base = "https://cdn.jsdelivr.net/npm/mathjax@3/es5";
    win.MathJax = {
      options: { enableMenu: false },
      tex: {
        inlineMath:  [["$",  "$" ], ["\\(", "\\)"]],
        displayMath: [["$$", "$$"], ["\\[", "\\]"]],
      },
      chtml: { fontURL: base + "/output/chtml/fonts/woff-v2" },
    };
    await import(base + "/tex-chtml.js");
  },

  // mml rendering: much smaller footprint
  //   (from https://docs.mathjax.org/en/latest/output/mathml.html)
  mathjax_mml: async () => {
    const base = "https://cdn.jsdelivr.net/npm/mathjax@3/es5";
    win.MathJax = {
      loader: {load: ["input/tex"],
               paths: { mathjax: base }},
      tex: {
        inlineMath:  [["$",  "$" ], ["\\(", "\\)"]],
        displayMath: [["$$", "$$"], ["\\[", "\\]"]],
      },
      startup: {
        pageReady() {
          win.MathJax._.mathjax.mathjax.handleRetriesFor(() => win.MathJax.startup.document.render());
        }
      },
      options: {
        renderActions: {
          typeset: [150,
                    (doc) => [...doc.math].forEach(math => win.MathJax.config.renderMathML(math, doc)),
                    (math, doc) => win.MathJax.config.renderMathML(math, doc)
                   ]
        },
      },
      renderMathML(math, doc) {
        math.typesetRoot = doc.createElement("mjx-container");
        math.typesetRoot.innerHTML = win.MathJax.startup.toMML(math.root);
        math.display && math.typesetRoot.setAttribute("display", 'block');
      }
    };
    doc.head.append(
      mk("style", "mjx-container[display=\"block\"] { display: block; margin: 1em 0; }"));
    await import(base + "/startup.js");
  },

};

const chunksDone = [];

const doChunk = name =>
  chunksDone.includes(name) ? ""
  : !(name in chunks) ? `[[[:error:\nUnknown chunk: \`${name}\`\n]]]\n\n`
  : (chunks[name](), chunksDone.push(name), "");

export const eliChunks = s => {
  const use = s.match(/^\s*<use:([\w, ]+)>(?: *\n)*/);
  return [...conf?.defaultChunks ?? [],
          ...use?.[1].trim().split(/ *, */g) ?? []
         ].map(doChunk).join("")
         + s.slice(use?.[0].length ?? 0);
};
