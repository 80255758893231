// Based on replacements.mjs

const emojis = [
  [/^merged?$/,       "👍"],
  [/^done$/,          "👍"],
  [/^reject(ed)?$/,   "👎"],
  [/^abandon(ed)?$/,  "👎"],
  [/^wait(ing)?$/,    "✋"],
  [/^(in[-_])?progress$/, "✍"],
  [/^open(ed)?$/,     "☝"],
  [/^closed?$/,       "👌"],
  [/^block(ed)?$.*/,  "✊"],
  [/^smiley?$/,       "☺"],
  [/^cont(inued?)?$/, "⏰"],
  [/^slow$/ ,         "🐌"],
];

const replacements = state => {
  for (const block of state.tokens) {
    if (block.type !== "inline") continue;
    let inAutolink = 0;
    for (const token of block.children) {
      if (token.type === "text" && !inAutolink)
        token.content = token.content
          .replaceAll(/(?:^|(?<!\.))(\.\.\.)(?:$|(?!\.))/g, "…")
          .replaceAll(/(?:^|(?<!-))(---?)(?:$|(?!-))/g,
                      (_, s) => s.length === 2 ? "–" : "—");
      else if (token.type === "link_open" && token.info === "auto") inAutolink--;
      else if (token.type === "link_close" && token.info === "auto") inAutolink++;
    }
  }
};

import { doc } from "./globals.js";
import { replaceInTexts } from "./utils.js";
import { mk } from "./mk.js";

export const xformEmojis =
  replaceInTexts(/(?<=[^\p{L}\p{N}\/]|^):(\w[\w-]*):(?=[^\p{L}\p{N}\/]|$)/gu, m => {
    for (const r of emojis) if (r[0].test(m[1]))
      return mk("span", { title: m[1] }, r[1]);
    return m[0];
  });

export const eliReplacements = md =>
  md.core.ruler.after("replacements", "eli_replacements", replacements);
