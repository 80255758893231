// Based on markdown-it-container

export const eliContainers = (opener, closer = opener) => md => {
  const render = (tokens, idx, opts, env, slf) => {
    const token = tokens[idx];
    const { params, details, summary } = token.info;
    let extra = "";
    if (token.nesting === 1) {
      if (params) {
        const words = params.filter(w => w.match(/^#?[a-zA-Z_][\w-]*$/));
        const ids = words.filter(w => w.startsWith("#")).map(w => w.substring(1));
        const classes = words.filter(w => !w.startsWith("#"));
        if (ids.length) token.attrJoin("id", ids[0]);
        if (classes.length) token.attrJoin("class", classes.join(" "));
      }
      if (details === "open") token.attrJoin("open", "true");
      if (summary)
        extra = details ? `<summary>${summary}</summary>\n` : `${summary}\n`;
    }
    return slf.renderToken(tokens, idx, opts, env, slf) + extra;
  };

  const openCh = opener.charCodeAt(0);
  const closeCh = closer.charCodeAt(0);
  const markerLen = 3;
  const differentCh = closeCh !== openCh;

  const headerInfo = (state, start, pos, max) => {
    const src = state.src;
    const markup = src.slice(start, pos);
    const rest = src.slice(pos, max);
    const args = rest.match(/^(\+?>)?(?::(?:(?! )([^:]*):?)?)?/);
    // Use a syntax that will get filtered out during rendering
    const details = !args?.[1] ? null : args[1] === "+>" ? "open" : "closed";
    const params = args?.[2]?.split(/\s+/g);
    const textStart = args?.[0].length ?? 0;
    const summarySrc = rest.slice(textStart).trim();
    const summary = !summarySrc.length ? null : md.render(summarySrc).trim();
    return [markup, { params, details, summary }];
  };

  const container = (state, startLine, endLine, silent) => {
    const start = state.bMarks[startLine] + state.tShift[startLine];
    const max = state.eMarks[startLine];
    if (state.src.charCodeAt(start) !== openCh) return false; // early abort
    let pos = start + 1;
    for (; pos <= max; pos++) if (state.src.charCodeAt(pos) !== openCh) break;
    const markerCount = pos - start;
    if (markerCount < markerLen) return false;
    // Since start is found, we can report success here in validation mode
    if (silent) return true;
    const [markup, info] = headerInfo(state, start, pos, max);
    let nextLine = startLine;
    // Search for the end of the block
    const findEnd = count => {
      while (true) {
        nextLine++;
        // unclosed block should be autoclosed by end of document.
        // also block seems to be autoclosed by end of parent
        if (nextLine >= endLine) return null;
        //
        const start = state.bMarks[nextLine] + state.tShift[nextLine];
        const max = state.eMarks[nextLine];
        //
        // non-empty line with negative indent should stop the list:
        // - ```
        //  test
        if (start < max && state.sCount[nextLine] < state.blkIndent) return null;
        //
        const firstCh = state.src.charCodeAt(start);
        const isOpen = differentCh && firstCh === openCh;
        if (!isOpen && firstCh !== closeCh) continue;
        //
        // closing fence should be indented less than 4 spaces
        if (!isOpen && state.sCount[nextLine] - state.blkIndent >= 4) continue;
        //
        for (pos = start + 1; pos <= max; pos++)
          if (state.src.charCodeAt(pos) !== firstCh) break;
        //
        // closing code fence must be at least as long as the opening one
        const curCount = pos - start;
        if (curCount < count) continue;
        //
        if (isOpen) { findEnd(curCount); continue; }
        else if (differentCh) return start;
        //
        // possible nested with same marker chars => check if tail has only spaces
        pos = state.skipSpaces(pos);
        // not just spaces => it opens a new container, skip to its end
        if (pos < max) { findEnd(curCount); continue; }
        //
        // found!
        return start;
      }
      return null;
    };
    const closerStart = findEnd(markerCount);
    const oldParentType = state.parentType, oldLineMax = state.lineMax;
    state.parentType = "container";
    // this will prevent lazy continuations from ever going past our end marker
    state.lineMax = nextLine;
    const tag = info.details ? "details" : "div";
    Object.assign(state.push("eli_container_open", tag, 1),
                  { markup, block: true, info, map: [startLine, nextLine] });
    state.md.block.tokenize(state, startLine + 1, nextLine);
    Object.assign(state.push("eli_container_close", tag, -1),
                  { markup: closerStart === null ? "" : state.src.slice(start, pos), block: true });
    state.parentType = oldParentType;
    state.lineMax = oldLineMax;
    state.line = nextLine + (closerStart === null ? 0 : 1);
    return true;
  };

  md.block.ruler.after("fence", "eli_container", container, {
    alt: ["paragraph", "reference", "blockquote", "list"]
  });
  md.renderer.rules["eli_container_open"] = render;
  md.renderer.rules["eli_container_close"] = render;
};

import { mk } from "./mk.js";

export const xformDetails = mdElt => {
  // containers that didn't have summary text on the first line: drag the next
  // element to be the summary
  mdElt.querySelectorAll("details").forEach(elt => {
    if (elt.querySelector("summary")) return;
    const first = elt.firstElementChild;
    if (!first) return;
    const summary = mk("summary");
    elt.replaceChild(summary, first)
    summary.appendChild(first);
  });
  return mdElt;
};
