import { rxQuote, addReset } from "./utils.js";

const defaultEvalTokens = { open: "<|", close: "|>", multi: "|>" };
const evalTokens = {};
const evalRxs = {};

export const setEvalTokens = (newTokens = {}) => {
  Object.assign(evalTokens, newTokens);
  const open  = rxQuote(evalTokens.open);
  const close = rxQuote(evalTokens.close);
  const multi = rxQuote(evalTokens.multi);
  evalRxs.expr = RegExp(
    `(?:${open}(.*?)${close})`
    + `|(?<=^|\n)((?:${multi}[^\n]*\n)*${multi}[^\n]*(?=\n|$))`,
    "s");
  evalRxs.multi = RegExp(`^${multi} ?(.*)$`, "gm");
};
addReset(() => setEvalTokens(defaultEvalTokens));
setEvalTokens(defaultEvalTokens);

const toString = x => {
  while (typeof x === "function") x = x();
  return x === null || x === undefined ? ""
       : typeof x === "string" ? x
       : Array.isArray(x) ? x.flat(9).map(toString).join("") // flat => optimization
       : x.toString !== Object.prototype.toString ? x.toString()
       : `<span class="error">bad evaluation result: ${x}</span>`;
};

// https://stackoverflow.com/a/67396617
// let __scoped_eval__ = s =>
//   eval(`void (__scoped_eval__ = ${__scoped_eval__.toString()}); ${s}`);
// // This would be cute too, but would require the usual madness...
// const __eval__ = /* async */ s => {
//   try { return toString(/* await */ __scoped_eval__(s)); }
//   catch (e) { return `<span class="error">bad evaluation result: ${e}</span>`; }
// }

// The above would be nice, but relies on a direct eval, which might confuse
// esbuild, but worse: doesn't work in the same way in the browser. So give up
// and use the simple approach: avoid using `let`/`const`, use globals instead.
const __eval__ = s => {
  try { return toString((0,eval)(s)); }
  catch (e) { return `<span class="error">bad evaluation result: ${e}</span>`; }
}

let cur = null;

const doReplace = (m, r) => // manual JS-style replacement
  r.replace(/\$([$&]|\d\d?|<([\w_]+)>)/g, (_, x, g) =>
    x === "$" ? "$"
  : x === "&" ? r
  : g ? m.groups?.[g] ?? `$&lt;${g}&gt;`
  : m?.[+x] ?? _);

export const rxReplacer = (rx, r) => {
  if (!rx.sticky) rx = RegExp(rx.source, rx.flags + "y");
  return () => {
    rx.lastIndex = 0;
    const m = rx.exec(cur);
    if (!m) return null;
    cur = cur.slice(rx.lastIndex);
    return typeof r === "string" ? doReplace(m, r) : r(...m);
  };
};
export const rxGrab = rx => rxReplacer(rx, (...m) => m)();

export const evals = s => {
  cur = s;
  const r = [];
  while (true) {
    const m = cur.match(evalRxs.expr);
    if (!m) { r.push(cur); break; }
    r.push(cur.slice(0, m.index));
    cur = cur.slice(m.index + m[0].length);
    r.push(__eval__(m[1] ?? m[2].replaceAll(evalRxs.multi, "$1")));
  }
  cur = null;
  return r.join("");
};
