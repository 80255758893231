import { conf } from "./globals.js";
import { _do } from "./pipe.js";

export const xformLinkTargets = _do(elt =>
  elt.querySelectorAll("a[href]").forEach(a => {
    const href = a.getAttribute("href");
    if (!a.querySelector("img")) {
      if (a.textContent.trim() === "") a.textContent = href;
      if (href.replace(/^https?:\/\/|\/$/g, "")
          === a.textContent.trim().replace(/^https?:\/\/|\/$/g, "")
          && !a.querySelector("tt, code, kbd"))
        a.innerHTML = `<tt>${a.innerHTML}</tt>`;
    }
    if (!conf.links) return;
    if (conf.links === true || href.match(/:\/\//)) a.target = "_blank";
  }));
