import hljs from "highlight.js/lib/core";
import { isBrowser } from "./globals.js";
import { syncFetch } from "./utils.js";

// Bad hack to make it load only the needed language files dynamically:
// * Arrange for a compile-time generated module ("generated-langs.js") with
//   meta language info
// * This is created in "build.js", based on the language list from here(!)
// * In node, all languages are registered -- for testing
// * In a browser, fetch the code for a language and evaluate it when needed
//   (would be nice to use import(), but that's always async)

export const langDir = isBrowser ? "./.md.lib/lang" : "highlight.js/lib/languages";
export const langs = isBrowser ? (await import("./generated-langs.js")).langs
  : (await import("node:fs")).readdirSync("node_modules/" + langDir)
      .filter(f => f.endsWith(".js") && !f.endsWith(".js.js"))
      .map(f => f.slice(0, -3));

if (!isBrowser)
  for (const lang of langs)
    hljs.registerLanguage(lang, (await import(`${langDir}/${lang}`)).default);

const dynamicLoadLang = lang => {
  if (!langs.includes(lang)) lang = langs.aliases[lang];
  if (!lang) return false;
  hljs.registerLanguage(
    lang,
    Function(`const module = {};${syncFetch(`/.md.lib/lang/${lang}.js`)}; return module.exports;`)());
  return true;
};

const myAliases = { racket: "scheme" };

export const highlight = (str, lang) => {
  if (!lang) return "";
  if (lang in myAliases) lang = myAliases[lang];
  if (!hljs.getLanguage(lang)) {
    if (!isBrowser) return ""; // all languages should be registered
    if (!dynamicLoadLang(lang)) return "";
  }
  try { return hljs.highlight(str, { language: lang }).value; } catch { }
  return "";
};
