import { conf, win, doc } from "./globals.js";
import { _q, _qa, kosherizeScripts } from "./utils.js";
import { render } from "./md.js";
import { mk } from "./mk.js";
import "./style.css";
import "highlight.js/styles/atom-one-dark.css";

// ============================================================================
// Markdown elements

const renderLocalMarkdown = parent => {
  let mds = [], n;
  const iter = doc.createNodeIterator(
    doc, win.NodeFilter.SHOW_COMMENT | win.NodeFilter.SHOW_ELEMENT);
  while (n = iter.nextNode()) {
    if (n instanceof Comment && /^--+\n/.test(n.data)) {
      mds.push(n.data.replace(/^--+\n|\n--+$/g, ""));
    } else if (n instanceof Element && n.nodeName.toLowerCase() === "pre"
               && n.classList.contains("md")) {
      mds.push(n.innerHTML); // preserve possible html in the text
      n.remove();
    }
  }
  if (!mds.length) return;
  renderElt(parent, mds.map(s => dedent(s).trim()).join("\n\n"));
};

const renderFileMarkdown = async parent => {
  const { origin, pathname } = location;
  const url = origin + pathname + (pathname.endsWith("/") ? "index" : "") + ".md";
  let elt = null, r = null, last = null, lastTime = 0;
  const update = async () => {
    { const now = Date.now();
      if (now - lastTime < 500) return;
      else lastTime = now;
    }
    r = await fetch(
      url, !last ? {} : { headers: { "if-modified-since": last } });
    if (!r.ok) return;
    last = r.headers.get("last-modified");
    elt = renderElt(parent, await r.text(), elt);
  };
  await update();
  if (!widgets || !conf.widgets?.includes("updater")) return;
  let running = false;
  const doUpdate = ()=> !doc.hidden && update();
  const toggle = () => {
    const on = button.classList.toggle("on");
    if (!!running === on) return;
    if (on) {
      setInterval(update, 20000);
      doc.addEventListener("visibilitychange", doUpdate);
      win.addEventListener("focus", doUpdate);
    } else {
      clearInterval(running);
      doc.removeEventListener("visibilitychange", doUpdate);
      win.removeEventListener("focus", doUpdate);
    }
  };
  const button = mk("button", "AutoUpdate");
  widgets.appendChild(button);
  button.addEventListener("click", toggle);
};

const renderElt = (parent, txt, elt0) => {
  const elt = elt0 || mk("div", { className: "md-rendered", autofocus: "" });
  const getAsText = conf.widgets?.includes("as-text") ? x => asText = x : null;
  render(Object.assign(txt, { elt, getAsText }));
  if (!elt0) parent.append(elt);
  kosherizeScripts(elt);
  return elt;
};

// ============================================================================
// Scroll position restorer

const initScrollRestorer = () => {
  const elts = [...new Set([..._qa(".md-rendered")].flatMap(e => parentList(e)))];
  win.addEventListener("md-rendered", () => {
    try {
      const saved = JSON.parse(sessionStorage.SavedScrollPositions);
      if (saved.length === elts.length)
        elts.forEach((e, i) => e.scrollTop = saved[i]);
    } catch { }
  });
  win.addEventListener("beforeunload", () =>
    win.sessionStorage.SavedScrollPositions =
      JSON.stringify(elts.map(e => e.scrollTop)));
};

// ============================================================================

let asText = null;

const makeCopyAsTextWidget = ()=> {
  const label = mk("div", "Get Text");
  const button = mk("button", label);
  button.addEventListener("click", ()=> {
    if (!asText) return;
    win.navigator.clipboard.writeText(asText);
    label.style.width = label.offsetWidth + "px";
    const origText = label.innerText;
    label.innerText = "Copied!";
    setTimeout(() => (label.innerText = origText, label.style.width = ""), 1000);
  });
  widgets.appendChild(button);
};

// ============================================================================

let widgets = null;

const init = async () => {
  const parent = _q(conf.parentElt);
  if (conf.widgets) {
    parent.appendChild(widgets = mk("div", { id: "widgets" }));
    makeCopyAsTextWidget();
  }
  await renderLocalMarkdown(parent);
  await renderFileMarkdown(parent);
  for (const sel of "title,h1,h2,h3,h4,h5".split(","))
    if (doc.title = _q(`.md-rendered ${sel}`)?.textContent ?? "")
      break;
  initScrollRestorer();
  win.dispatchEvent(new CustomEvent("md-rendered"));
};

// ============================================================================

// https://developer.apple.com/forums/thread/651215
if (document.readyState !== "loading") init();
else doc.addEventListener("DOMContentLoaded", init);
