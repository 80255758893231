// Convenient element creation (with possibly nested attributes)

import { win, doc } from "./globals.js";

export const mk = (tag, ...elts) => {
  const attrs = elts.length && isPlainObject(elts[0]) && elts.shift();
  const elt = doc.createElement(tag);
  const aloop = (o, as) =>
    Object.entries(as).forEach(([a, v]) =>
      isPlainObject(v) ? aloop(o[a] ??= {}, v) : o[a] = v);
  if (attrs) aloop(elt, attrs);
  const loop = e => e && (
    e instanceof win.Node ? elt.appendChild(e)
    : Array.isArray(e) ? e.forEach(loop)
    : typeof e !== "string" && e?.[Symbol.iterator] ? loop(Array.from(e))
    : loop(doc.createTextNode(e))
  );
  elts.forEach(loop);
  return elt;
};

const isPlainObject = x => {
  if (typeof x !== "object" || x === null) return false;
  const prototype = Object.getPrototypeOf(x);
  return prototype === Object.prototype || prototype === null;
};
