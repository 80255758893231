const defaultConf = {
  widgets: null, // null or a list of widgets: "updater", "as-text"
  defaultChunks: null, // null or list of default chunk names (from `eli-chunks.js`)
  parentElt: "body", // selector for md container
  links: "full", // full-links open in new tabs, true to make all do that, null for none
};

export const isBrowser = typeof window !== "undefined";

export const [conf, win, doc] = await (async ()=>{
  if (isBrowser) {
    globalThis.conf = { ...defaultConf, ...(globalThis.conf ?? {}) };
    return [globalThis.conf, globalThis.window, globalThis.document];
  } else {
    const { JSDOM } = (await import("jsdom"));
    const dom = new JSDOM("<!doctype html><html><body></body><html>", { url: "https://localhost/" });
    // make some toplevel values too, in case random code uses it
    globalThis.localStorage = dom.window.localStorage;
    return [globalThis.conf = defaultConf,
            globalThis.window = dom.window,
            globalThis.document = dom.window.document];
  }
})();
