// Inline plugin for markdown-it, based on markdown-it-ins, but generalized

export const eliInlines = (op, tag) => {
  if (op.length < 1 || op.length > 2) throw Error(`bad operator length: "${op}"`);
  const opCode = op.charCodeAt(0), opLen = op.length;
  const openType = op + "_open", closeType = op + "_close";
  if (opLen === 2 && op[0] !== op[1]) throw Error(`bad operator contents: "${op}"`);
  return md => {
    // Insert each marker as a separate text token, and add it to delimiter list
    const tokenize = (state, silent) => {
      if (silent) return false;
      if (state.src.charCodeAt(state.pos) !== opCode) return false;
      const scanned = state.scanDelims(state.pos, true);
      const len = scanned.length;
      if (len < opLen) return false;
      state.pos += len;
      if (!scanned.can_open && !scanned.can_close) {
        state.push("text", "", 0).content = op[0].repeat(len);
      } else for (let i = len, j = 0; i > 0; i -= opLen, ++j) {
        const last1 = i < opLen; // => leftover char(s)
        state.push("text", "", 0).content = last1 ? op.substring(0, i) : op;
        if (last1) break;
        state.delimiters.push({
          marker: opCode,
          length: 0, // disable "rule of 3" length checks meant for emphasis
          jump: j,
          token: state.tokens.length - 1,
          end: -1,
          open: scanned.can_open,
          close: scanned.can_close,
        });
      }
      return true;
    };
    // Walk through delimiter list and replace text tokens with tags
    const postProcess = (state, delimiters) => {
      const tokens = state.tokens;
      for (const startDelim of delimiters) {
        if (startDelim.marker !== opCode) continue;
        if (startDelim.end === -1) continue;
        const startToken = tokens[startDelim.token];
        const endDelim = delimiters[startDelim.end];
        const set = (t, type, nesting) => {
          t.type = type; t.tag = tag; t.nesting = nesting; t.markup = op; t.content = "";
        }
        set(startToken, openType, 1);
        set(tokens[endDelim.token], closeType, -1);
        const nextT = endDelim.token + 1;
        if (opLen > 1 && nextT < tokens.length
            && tokens[nextT].type === "text" && tokens[nextT].content === op[0]) {
          // If a marker sequence has extra characters, it's split as: `xxxxx`
          // -> `xx` + `xx` + `x`, leaving one at the end of the sequence --
          // adjust openings to match the closing markers by swapping the
          // first/last.
          let j = nextT;
          while (j-1 >= 0 && tokens[j-1].type === closeType) j--;
          if (j === nextT) return;
          const tmp = tokens[j]; tokens[j] = tokens[nextT]; tokens[nextT] = tmp;
        }
      }
    };
    md.inline.ruler .before("strikethrough", "eli_" + tag, tokenize);
    md.inline.ruler2.before("strikethrough", "eli_" + tag, state => {
      postProcess(state, state.delimiters);
      if (state.tokens_meta?.length)
        for (const curr of state.tokens_meta)
          if (curr?.delimiters) postProcess(state, curr.delimiters);
    });
  };
};
