// ----------------------------------------------------------------------------
// Lists: modified from lib/rules_block/list.mjs

import { rxQuote } from "./utils.js";

const labelItems = {
  "*":  "•", "**": "•",
  "+":  "+", "++": "+", "*+": "+", "+*": "+",
  "-":  "-", "--": "-", "*-": "-", "-*": "-",
  " ":  " ",
  "v":  "✓",
  "x":  "✗",
  "?":  "❔", "??": "🤔", "*?": "🤔", "?*": "🤔",
  "!":  "❕", "!!": "💥", "*!": "💥", "!*": "💥",
  "->": "➔",
  "=>": "🡆",
  // "=>": "➭", // this isn't in macos's default font too
};
const boringLabels = ["*", "+", "-"]; // only these => no labels

const labelPfx = "<label><tt>";
const labelSfx = "</tt></label>";

const useAsShort = marker => /..|^[^ \w]$/.test(marker);

const labelItemsRx =
  RegExp("(?<m0>\\* ?(?:\\[(?<m1>[^\n\\[\\]]+)\\]|{(?<m2>[^\n{}]+)})|"
         + Object.keys(labelItems)
             .filter(useAsShort)
             .sort((a, b) => b.length - a.length)
             .map(rxQuote)
             .join("|")
         + ")(?= |\r?\n|$)",
         "y");

let label = null, boring, markerWidth = 0;

const skipBulletListMarker = (state, startLine, silent) => {
  labelItemsRx.lastIndex = state.bMarks[startLine] + state.tShift[startLine];
  const m = labelItemsRx.exec(state.src);
  if (!m) return -1;
  if (silent) return labelItemsRx.lastIndex;
  const { groups: { m0, m1, m2 } } = m;
  boring = boringLabels.includes(m0);
  markerWidth = m0.length;
  label = m1 || m2 || m0;
  return labelItemsRx.lastIndex;
};

const labelLastItem = state => {
  const item = state.tokens.at(-1);
  item.attrs = [["type", label]];
  item.meta = !boring;
  // The label + tt are needed for rendering the label. Can be generated by CSS
  // using list-style-type: "...", but that's not selectable / copy-able.
  state.push("html_inline", "", 0).content =
    labelPfx + (labelItems[label] || label) + labelSfx + "\n";
  label = null;
}

// Remove labels if all are just "*"
const maybeBoring = tokens => {
  const last = tokens.length - 1, level = tokens[last].level;
  if (tokens[last].type !== "bullet_list_close") return;
  const first = tokens.findLastIndex(t =>
    t.type === "bullet_list_open" && t.level === level);
  if (tokens.slice(first).some(t =>
        t.type === "list_item_open" && t.level > level && t.meta))
    return; // at least one interesting item: leave all as labeled
  for (let i = last; i >= first; i--) {
    const t = tokens[i];
    if (t.type === "list_item_open" && t.level === level+1)
      t.attrs = [];
    else if (t.type === "html_inline" && t.level === level+2
             && t.content.startsWith(labelPfx))
      tokens.splice(i, 1);
  }
};

// ---- Modified code start ---------------------------------------------------
// Search `[-+*][\n ]`, returns next pos after marker on success
// or -1 on fail.
//ELI function skipBulletListMarker (state, startLine) {
//ELI   const max = state.eMarks[startLine]
//ELI   let pos = state.bMarks[startLine] + state.tShift[startLine]
//ELI
//ELI   const marker = state.src.charCodeAt(pos++)
//ELI   // Check bullet
//ELI   if (marker !== 0x2A/* * */ &&
//ELI       marker !== 0x2D/* - */ &&
//ELI       marker !== 0x2B/* + */) {
//ELI     return -1
//ELI   }
//ELI
//ELI   if (pos < max) {
//ELI     const ch = state.src.charCodeAt(pos)
//ELI
//ELI     if (!isSpace(ch)) {
//ELI       // " -test " - is not a list item
//ELI       return -1
//ELI     }
//ELI   }
//ELI
//ELI   return pos
//ELI }

// Search `\d+[.)][\n ]`, returns next pos after marker on success
// or -1 on fail.
function skipOrderedListMarker (state, startLine) {
  const start = state.bMarks[startLine] + state.tShift[startLine]
  const max = state.eMarks[startLine]
  let pos = start

  // List marker should have at least 2 chars (digit + dot)
  if (pos + 1 >= max) { return -1 }

  let ch = state.src.charCodeAt(pos++)

  if (ch < 0x30/* 0 */ || ch > 0x39/* 9 */) { return -1 }

  for (;;) {
    // EOL -> fail
    if (pos >= max) { return -1 }

    ch = state.src.charCodeAt(pos++)

    if (ch >= 0x30/* 0 */ && ch <= 0x39/* 9 */) {
      // List marker should have no more than 9 digits
      // (prevents integer overflow in browsers)
      if (pos - start >= 10) { return -1 }

      continue
    }

    // found valid marker
    //ELI if (ch === 0x29/* ) */ || ch === 0x2e/* . */) {
    if (ch === 0x2e/* . */) { //ELI
      break
    }

    return -1
  }

  if (pos < max) {
    ch = state.src.charCodeAt(pos)

    if (!state.md.utils.isSpace(ch)) {
      // " 1.test " - is not a list item
      return -1
    }
  }
  markerWidth = -1 //ELI
  return pos
}

function markTightParagraphs (state, idx) {
  const level = state.level + 2

  for (let i = idx + 2, l = state.tokens.length - 2; i < l; i++) {
    if (state.tokens[i].level === level && state.tokens[i].type === 'paragraph_open') {
      state.tokens[i + 2].hidden = true
      state.tokens[i].hidden = true
      i += 2
    }
  }
}

function list (state, startLine, endLine, silent) {
  let max, pos, start, token
  let nextLine = startLine
  let tight = true

  // if it's indented more than 3 spaces, it should be a code block
  if (state.sCount[nextLine] - state.blkIndent >= 4) { return false }

  // Special case:
  //  - item 1
  //   - item 2
  //    - item 3
  //     - item 4
  //      - this one is a paragraph continuation
  if (state.listIndent >= 0 &&
      state.sCount[nextLine] - state.listIndent >= 4 &&
      state.sCount[nextLine] < state.blkIndent) {
    return false
  }

  let isTerminatingParagraph = false

  // limit conditions when list can interrupt
  // a paragraph (validation mode only)
  if (silent && state.parentType === 'paragraph') {
    // Next list item should still terminate previous list item;
    //
    // This code can fail if plugins use blkIndent as well as lists,
    // but I hope the spec gets fixed long before that happens.
    //
    if (state.sCount[nextLine] >= state.blkIndent) {
      isTerminatingParagraph = true
    }
  }

  // Detect list type and position after marker
  let isOrdered
  let markerValue
  let posAfterMarker
  if ((posAfterMarker = skipOrderedListMarker(state, nextLine)) >= 0) {
    isOrdered = true
    start = state.bMarks[nextLine] + state.tShift[nextLine]
    markerValue = Number(state.src.slice(start, posAfterMarker - 1))

    // If we're starting a new ordered list right after
    // a paragraph, it should start with 1.
    if (isTerminatingParagraph && markerValue !== 1) return false
  } else if ((posAfterMarker = skipBulletListMarker(state, nextLine)) >= 0) {
    isOrdered = false
    //ELI Add another condition: if we're on the first line, and we see the
    //ELI following line with the original indentation that is not a bullet,
    //ELI and (this is one-char and not a boring marker) or (we're breaking a
    //ELI paragraph) then fail this item. This should avoid some mistaken list
    //ELI starts. (!silent is to do this only on the first item)
    if ((isTerminatingParagraph || !silent && markerWidth === 1 && !boring) //ELI
        && nextLine+1 < endLine //ELI
        && state.sCount[nextLine+1] === state.blkIndent //ELI
        && !state.isEmpty(nextLine+1) //ELI
        && skipBulletListMarker(state, nextLine+1, true) < 0) { //ELI
      return false //ELI
    } //ELI
  } else {
    return false
  }

  // If we're starting a new unordered list right after
  // a paragraph, first line should not be empty.
  const isEmptyRest = state.skipSpaces(posAfterMarker) >= state.eMarks[nextLine] //ELI
  if (isTerminatingParagraph) {
    //ELI if (state.skipSpaces(posAfterMarker) >= state.eMarks[nextLine]) return false
    if (isEmptyRest) return false //ELI
  }
  else if (isEmptyRest && markerWidth === 1 && !boring) { //ELI
    //ELI if we're starting a ul (regardless of breaking a paragraph),
    //ELI and the label is one char and not boring, then fail (eg a single "?"
    //ELI after some code)
    return false //ELI
  } //ELI

  // For validation mode we can terminate immediately
  if (silent) { return true }

  // We should terminate list on style change. Remember first one to compare.
  //ELI const markerCharCode = state.src.charCodeAt(posAfterMarker - 1)

  // Start list
  const listTokIdx = state.tokens.length

  if (isOrdered) {
    token       = state.push('ordered_list_open', 'ol', 1)
    if (markerValue !== 1) {
      token.attrs = [['start', markerValue]]
    }
  } else {
    token       = state.push('bullet_list_open', 'ul', 1)
  }

  const listLines = [nextLine, 0]
  token.map    = listLines
  //ELI token.markup = String.fromCharCode(markerCharCode)

  //
  // Iterate list items
  //

  let prevEmptyEnd = false
  const terminatorRules = state.md.block.ruler.getRules('list')

  const oldParentType = state.parentType
  state.parentType = 'list'

  while (nextLine < endLine) {
    pos = posAfterMarker
    max = state.eMarks[nextLine]

    const initial = state.sCount[nextLine] + posAfterMarker - (state.bMarks[nextLine] + state.tShift[nextLine])
    let offset = initial

    while (pos < max) {
      const ch = state.src.charCodeAt(pos)

      if (ch === 0x09) {
        offset += 4 - (offset + state.bsCount[nextLine]) % 4
      } else if (ch === 0x20) {
        offset++
      } else {
        break
      }

      pos++
    }

    const contentStart = pos
    let indentAfterMarker

    if (contentStart >= max) {
      // trimming space in "-    \n  3" case, indent is 1 here
      indentAfterMarker = 1
      //ELI No idea why both commonmark and markdown-it make this:
      //ELI     >+
      //ELI     >  foo
      //ELI be an empty item followed by a foo paragraph. Changing this to 0
      //ELI makes it behave like I expect.
      //ELI Reported: https://github.com/commonmark/commonmark-spec/issues/766
      indentAfterMarker = 0 //ELI
    } else {
      indentAfterMarker = offset - initial
    }

    // If we have more than 4 spaces, the indent is 1
    // (the rest is just indented code block)
    if (indentAfterMarker > 4) { indentAfterMarker = 1 }

    // "  -  test"
    //  ^^^^^ - calculating total length of this thing
    const indent = initial + indentAfterMarker

    // Run subparser & write tokens
    token        = state.push('list_item_open', 'li', 1)
    //ELI token.markup = String.fromCharCode(markerCharCode)
    const itemLines = [nextLine, 0]
    token.map    = itemLines
    if (isOrdered) {
      token.info = state.src.slice(start, posAfterMarker - 1)
    }
    else labelLastItem(state) //ELI

    // change current state, then restore it after parser subcall
    const oldTight = state.tight
    const oldTShift = state.tShift[nextLine]
    const oldSCount = state.sCount[nextLine]

    //  - example list
    // ^ listIndent position will be here
    //   ^ blkIndent position will be here
    //
    const oldListIndent = state.listIndent
    state.listIndent = state.blkIndent
    state.blkIndent = indent

    state.tight = true
    state.tShift[nextLine] = contentStart - state.bMarks[nextLine]
    state.sCount[nextLine] = offset

    if (contentStart >= max && state.isEmpty(nextLine + 1)) {
      // workaround for this case
      // (list item is empty, list terminates before "foo"):
      // ~~~~~~~~
      //   -
      //
      //     foo
      // ~~~~~~~~
      state.line = Math.min(state.line + 2, endLine)
    } else {
      if (contentStart >= max) //ELI
        state.blkIndent -= (markerWidth < 0 ? 0 : markerWidth-1) //ELI
      state.md.block.tokenize(state, nextLine, endLine, true)
    }

    // If any of list item is tight, mark list as tight
    if (!state.tight || prevEmptyEnd) {
      tight = false
    }
    // Item become loose if finish with empty line,
    // but we should filter last element, because it means list finish
    prevEmptyEnd = (state.line - nextLine) > 1 && state.isEmpty(state.line - 1)

    state.blkIndent = state.listIndent
    state.listIndent = oldListIndent
    state.tShift[nextLine] = oldTShift
    state.sCount[nextLine] = oldSCount
    state.tight = oldTight

    token        = state.push('list_item_close', 'li', -1)
    //ELI token.markup = String.fromCharCode(markerCharCode)

    nextLine = state.line
    itemLines[1] = nextLine

    if (nextLine >= endLine) { break }

    //
    // Try to check if list is terminated or continued.
    //
    if (state.sCount[nextLine] < state.blkIndent) { break }

    // if it's indented more than 3 spaces, it should be a code block
    if (state.sCount[nextLine] - state.blkIndent >= 4) { break }

    // fail if terminating block found
    let terminate = false
    for (let i = 0, l = terminatorRules.length; i < l; i++) {
      if (terminatorRules[i](state, nextLine, endLine, true)) {
        terminate = true
        break
      }
    }
    if (terminate) { break }

    // fail if list has another type
    if (isOrdered) {
      posAfterMarker = skipOrderedListMarker(state, nextLine)
      if (posAfterMarker < 0) { break }
      start = state.bMarks[nextLine] + state.tShift[nextLine]
    } else {
      posAfterMarker = skipBulletListMarker(state, nextLine)
      if (posAfterMarker < 0) { break }
    }

    //ELI if (markerCharCode !== state.src.charCodeAt(posAfterMarker - 1)) { break }
  }

  // Finalize list
  if (isOrdered) {
    token = state.push('ordered_list_close', 'ol', -1)
  } else {
    token = state.push('bullet_list_close', 'ul', -1)
  }
  //ELI token.markup = String.fromCharCode(markerCharCode)

  maybeBoring(state.tokens) //ELI

  listLines[1] = nextLine
  state.line = nextLine

  state.parentType = oldParentType

  // mark paragraphs tight if needed
  if (tight) {
    markTightParagraphs(state, listTokIdx)
  }

  return true
}
// ---- Modified code end -----------------------------------------------------

export const eliLists = md =>
  md.block.ruler.after("list", "eli_lists", list, { alt: ['paragraph', 'reference', 'blockquote'] });
