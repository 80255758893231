import { isBrowser } from "./globals.js";
if (isBrowser) import("./main.js");

import * as utils from "./utils.js";
import * as mk from "./mk.js";
import * as md from "./md.js";
import * as pipe from "./pipe.js";
import * as evals from "./evals.js";
import hljs from "highlight.js/lib/core";

// Make exports available on the toplevel window namespace
[utils, mk, md, pipe, evals, { hljs }]
  .forEach(mod => Object.entries(mod).forEach(([id, val]) =>
    globalThis[id] ??= val));
